import { Routes, Route } from "react-router-dom";

import Homepage from "./pages/Homepage";
import Styleguide from "./pages/Styleguide";
import Navigation from "./components/Navigation";

function App() {
    return (
        <div className="App overflow-x-hidden">
            <Navigation />
            <main className="pt-[60px] md:pt-[80px] pb-28">
                <Routes>
                    <Route path="/">
                        <Route index element={<Homepage />} />
                        <Route path="/styleguide" element={<Styleguide />} />
                    </Route>
                </Routes>
            </main>
        </div>
    );
}

export default App;

import Button from "./Button";

const Newsletter = () => {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
            <div className="flex flex-col gap-8">
                <h2 className="relative">Immer informiert in Sachen Familienorganisation mit unserem <span className="inline-block text-transparent bg-clip-text bg-gradient-text">Newsletter</span></h2>
                <p>Lorem ipsum dolor sit amet consectetur. Eget feugiat quam rhoncus praesent sagittis. Habitasse in orci sit a mattis. Dui nisi sodales magna amet malesuada mauris orci</p>
            </div>
            <div className="relative flex flex-col items-center justify-center">
                <form className="relative w-full max-w-[600px] mx-auto" action="/">
                    <input type="email" className="bg-[#F5F5F5] w-full px-8 py-6 focus:outline-none rounded-full pr-[177px]" name="email" id="email" placeholder="E-Mail eingeben" />
                    <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                        <Button elementType="button" type="submit" className="btn-primary">Abonnieren</Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Newsletter;
import React, { memo } from "react";
import Button from "./Button";

const FeaturedBadge = memo(({ isFeatured }) => isFeatured && (
    <span className="inline-flex w-8 h-8 rounded-full bg-featured flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path d="M10.224 6.293C10.224 6.293 10.97 1.905 7.92599 0C7.88064 0.753945 7.67675 1.48984 7.32765 2.15963C6.97854 2.82941 6.49206 3.41802 5.89999 3.887C4.59999 5.03 2.15499 7.6 2.18099 10.34C2.17165 11.5113 2.48982 12.6619 3.09959 13.662C3.70936 14.6621 4.58648 15.4719 5.63199 16C5.66934 15.4711 5.81188 14.955 6.05125 14.482C6.29062 14.0089 6.62201 13.5884 7.02599 13.245C7.36832 12.9817 7.6548 12.6529 7.86872 12.2778C8.08265 11.9027 8.21974 11.4887 8.27199 11.06C9.16692 11.5358 9.92306 12.2356 10.4664 13.0912C11.0098 13.9468 11.3218 14.9287 11.372 15.941V15.954C12.3588 15.502 13.2013 14.7858 13.8063 13.8846C14.4113 12.9835 14.7552 11.9325 14.8 10.848C15.016 8.274 13.606 4.774 12.355 3.63C11.8827 4.68445 11.1492 5.601 10.224 6.293Z" fill="white" />
        </svg>
    </span>
));

const Badge = memo(({ badge, className }) => (
    <span className={`inline-flex py-2 px-6 rounded-full ${className}`}>
        {badge}
    </span>
));

const AuthorAndDate = memo(({ author, date }) => (
    <div className="flex gap-2 items-center">
        {author && author.trim() && (
            <>
                <small className="text-button font-semibold font-decorative">{author}</small>
                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="5" viewBox="0 0 4 5" fill="none">
                    <circle cx="2" cy="2.5" r="2" fill="#949494" />
                </svg>
            </>
        )}
        <small className="text-muted">{date}</small>
    </div>
));

const CardLayout = memo(({ children, className }) => (
    <article className={`grid w-full grid-cols-12 overflow-hidden rounded-[20px] relative ${className}`}>
        {children}
    </article>
));

const DefaultCard = memo(({ item }) => (
    <CardLayout className="col-span-full lg:col-span-8">
        <figure className="relative h-full aspect-[16/9] md:aspect-[5/6] overflow-hidden col-span-full md:col-span-5">
            <img className="w-full h-full object-cover object-center" src={item.imageSrc} alt={item.title} />
        </figure>
        <div className="p-8 bg-gradient-primary col-span-full md:col-span-7 flex flex-col relative">
            <div className="flex gap-2 items-center">
                <FeaturedBadge isFeatured={item.featured} />
                <Badge badge={item.badge} className="bg-badge-primary" />
            </div>
            <h2 className="text-h4 font-semibold py-3">{item.title}</h2>
            <AuthorAndDate author={item.author} date={item.date} />
            <p className="mt-6 mb-6 leading-normal">{item.description}</p>
            <Button to={item.actionLink} className="mt-auto" variant="secondary-var-1">{item.actionText}</Button>
        </div>
    </CardLayout>
));

const MiniCard = memo(({ item }) => (
    <CardLayout className="col-span-full lg:col-span-4">
        <div className="p-8 bg-gradient-secondary col-span-full flex flex-col">
            <div className="flex gap-2 items-center mb-6">
                <FeaturedBadge isFeatured={item.featured} />
                <Badge badge={item.badge} className="bg-badge-secondary" />
            </div>
            <img className="h-[150px] object-contain my-auto" src={item.imageSrc} alt={item.title} />
            <h2 className="text-h4 font-semibold mt-8">{item.title}</h2>
            <p className="mt-4 mb-6 leading-normal">{item.description}</p>
            <Button to={item.actionLink} className="mt-auto" variant="secondary-var-2">{item.actionText}</Button>
        </div>
    </CardLayout>
));

const BigCard = memo(({ item }) => (
    <CardLayout className="col-span-full">
        <div className="p-8 bg-gradient-primary col-span-full md:col-span-6 flex flex-col order-2 md:order-1">
            <div className="flex gap-2 items-center">
                <FeaturedBadge isFeatured={item.featured} />
                <Badge badge={item.badge} className="bg-badge-primary" />
            </div>
            <h2 className="font-semibold mt-4 mb-8">{item.title}</h2>
            <p className="mb-6 leading-normal">{item.description}</p>
            <Button to={item.actionLink} className="mt-auto" variant="secondary-var-1">{item.actionText}</Button>
        </div>
        <figure className="relative h-full aspect-[16/9] overflow-hidden col-span-full md:col-span-6 order-1 md:order-2">
            <img className="w-full h-full object-cover object-center" src={item.imageSrc} alt={item.title} />
        </figure>
    </CardLayout>
));

const cardComponents = {
    default: DefaultCard,
    mini: MiniCard,
    big: BigCard,
};

const Cards = ({ items }) => (
    <div className="grid lg:grid-cols-12 grid-cols-1 gap-12">
        {items.map((item, index) => {
            const CardComponent = cardComponents[item.type] || DefaultCard;
            return <CardComponent key={index} item={item} />;
        })}
    </div>
);

export default Cards;
import Button from "../components/Button";

function Styleguide() {
    return (
        <div className="container mx-auto pt-14 pb-14">
            <h2 className="text-h2 weight-600 pb-2 border-b mb-5 font-semibold mt-5">
                Farben
            </h2>

            <ul className="grid grid-cols-3 gap-6">
                <li className="text-primary">text-primary</li>
                <li className="text-text-color">text-text-color</li>
                <li className="text-border">text-error</li>
                <li className="text-background bg-slate-800">
                    text-background
                </li>
                <li className="text-error">text-error</li>
                <li className="text-success">text-success</li>
            </ul>
            <ul className="grid grid-cols-3 gap-6 mt-5">
                <li className="h-[150px] w-full bg-gradient-primary flex border border-border items-center text-center justify-center">
                    bg-gradient-primary
                </li>
                <li className="h-[150px] w-full bg-gradient-secondary flex border border-border items-center text-center justify-center">
                    bg-gradient-secondary
                </li>
                <li className="h-[150px] w-full bg-gradient-button flex border border-border items-center text-center justify-center">
                    bg-gradient-button
                </li>
                <li className="w-full h-[150px] bg-primary flex border border-border items-center text-center justify-center">
                    bg-primary
                </li>
                <li className="w-full h-[150px] bg-background flex border border-border items-center text-center justify-center">
                    bg-background
                </li>
                <li className="w-full h-[150px] bg-error flex border border-border items-center text-center justify-center">
                    bg-error
                </li>
                <li className="w-full h-[150px] bg-success flex border border-border items-center text-center justify-center">
                    bg-success
                </li>
            </ul>
            <h2 className="text-h2 weight-600 pb-2 border-b mb-5 font-semibold mt-12">
                Typografie
            </h2>
            <h1>Das ist Headline H1</h1>
            <h2>Das ist Headline H2</h2>
            <h3>Das ist Headline H3</h3>
            <h4>Das ist Headline H4</h4>
            <h5>Das ist Headline H5</h5>
            <h6>Das ist Headline H6</h6>
            <p>
                Das ist ein Textabschnitt. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.
            </p>
            <h2 className="text-h2 weight-600 pb-2 border-b mb-5 font-semibold mt-12">
                Buttons
            </h2>
            <div className="flex items-center gap-14 mt-5 flex-wrap">
                <Button to="/" className="btn-primary">
                    btn-primary
                </Button>
                <Button to="/about" className="btn-secondary-var-1">
                    btn-secondary-var-1
                </Button>
                <Button to="/about" className="btn-secondary-var-2">
                    btn-secondary-var-2
                </Button>
                <Button to="/">
                    Default
                </Button>
                <Button href="https://www.example.com" target="_blank" rel="noopener noreferrer">
                    External Link
                </Button>
            </div>
        </div>
    );
}

export default Styleguide;

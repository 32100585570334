import Cards from "../components/Cards";
import Newsletter from "../components/Newsletter";
import Topics from "../components/Topics";
import settings from "../config/settings";

function Homepage() {
    return (
        <>
            <section className="container h-full mt-8 lg:mt-16 mb-14 lg:mb-36">
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-[70px]">
                    <div className="flex h-auto flex-col gap-y-32 order-2 lg:order-1">
                        <h1 className="hyphens-auto relative">
                            Gemeinsam den Familienalltag meistern
                            <span className="hidden lg:inline-block w-[90px] h-[110px] bg-no-repeat bg-icon-decorative-arrow bottom-[-55px] absolute"></span>
                        </h1>
                        <div className="mt-auto">
                            <h2 className="mb-[25px]">Beliebte Themen von Heute</h2>
                            <Topics items={settings.topics} />
                        </div>
                    </div>
                    <div className="flex h-auto order-1 lg:order-2 justify-center">
                        <img className="w-auto h-auto max-w-full max-h-full object-contain" src="https://i.postimg.cc/TwMC7DtX/Frame-1.png" alt="Rocket" />
                    </div>
                </div>
            </section>

            <section className="container mb-14 lg:mb-36">
                <h2 className="mb-[25px] md:mb-[50px]">Neuste Artikel zum Thema Lebensorganisaition</h2>
                <Cards items={settings.cardsData} />
            </section>

            <section className="container mb-14 lg:mb-36">
                <Newsletter />
            </section>
        </>
    );
}

export default Homepage;

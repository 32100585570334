const settings = {
    logo: {
        alt: "Logo",
        src: "Logo.svg"
    },
    menu: [
        {
            label: "Home",
            to: "/"
        },
        {
            label: "Blog",
            to: "/"
        },
        {
            label: "Styleguide",
            to: "/styleguide"
        },
        {
            label: "Über uns",
            to: "/"
        },
    ],
    topics: [
        { name: 'Sport', link: '/sport' },
        { name: 'Kultur', link: '/kultur' },
        { name: 'Politik', link: '/politik' },
        { name: 'Bildung', link: '/bildung' },
        { name: 'Umwelt', link: '/umwelt' },
        { name: 'Reisen', link: '/reisen' },
        { name: 'Mode', link: '/mode' },
        { name: 'Musik', link: '/musik' },
        { name: 'Umwelt', link: '/umwelt' },
        { name: 'Reisen', link: '/reisen' },
        { name: 'Mode', link: '/mode' },
        { name: 'Musik', link: '/musik' }
    ],
    cardsData: [
        {
            type: "default",
            badge: "Ratgeber",
            title: 'Smarte Kinderzimmer: Diese Möglichkeiten gibt es',
            author: 'Philipp Biel',
            date: '16. April 2024',
            description: 'Smart-Home-Lösungen erobern unser Zuhause und sogar den Garten im Sturm. Kein Wunder, sorgen die intelligenten Geräte doch für mehr Wohnkomfort oder erhöhen die Sicherheit. Auch im Kinderzimmer gib es verschiedene Möglichkeiten, die für Spielspaß sorgen, eine wohlige Atmosphäre für die Kleinen schaffen oder die Sicherheit erhöhen und uns so ruhiger schlafen lassen. Welche Möglichkeiten es für smarte Kinderzimmer gibt, verraten wir in diesem Artikel.',
            imageSrc: 'https://images.pexels.com/photos/2026960/pexels-photo-2026960.jpeg',
            actionText: 'Mehr erfahren',
            actionLink: '/',
            featured: true,
        },
        {
            type: "mini",
            badge: "Organisation",
            title: 'Besser organisiert im Familienalltag mit dem FAMO-Planer',
            description: 'Lorem ipsum dolor sit amet consectetur. Eget feugiat quam rhoncus praesent sagittis. Habitasse in orci sit a mattis. Dui nisi sodales magna amet malesuada mauris orci',
            imageSrc: 'https://png.pngtree.com/png-clipart/20221006/original/pngtree-calendar-3d-icon-render-png-image_8661084.png',
            actionText: 'Mehr erfahren',
            actionLink: '/',
            featured: false,
        },
        {
            type: "big",
            badge: "Tipps & Tricks",
            title: 'Unser Tipp des Tages',
            description: 'Scheuen Sie sich nicht, um Hilfe zu bitten, wenn nötig. Delegieren Sie Haushaltsaufgaben an Ihren Partner, Familienmitglieder oder bezahlte Hilfe, um Ihre Last zu erleichtern. Denken Sie daran, dass es in Ordnung ist, Selbstfürsorge zu priorisieren und Pausen einzulegen, wenn nötig. Scheuen Sie sich nicht, um Hilfe zu bitten, wenn nötig. Delegieren Sie Haushaltsaufgaben an Ihren Partner, Familienmitglieder oder bezahlte Hilfe, um Ihre Last zu erleichtern. Denken Sie daran, dass es in Ordnung ist, Selbstfürsorge zu priorisieren und Pausen einzulegen, wenn nötig.Scheuen Sie sich nicht, um Hilfe zu bitten, wenn nötig. Delegieren Sie Haushaltsaufgaben an Ihren Partner,',
            imageSrc: 'https://images.pexels.com/photos/39691/family-pier-man-woman-39691.jpeg',
            actionText: 'Mehr erfahren',
            actionLink: '/',
            featured: false,
        },
        {
            type: "mini",
            badge: "Organisation",
            title: 'Na, wirklich an alles gedacht?',
            description: 'Lade dir bei uns den FAMO-Check runter, um zur EInschulung optimal auf alles vorbereitet zu sein.',
            imageSrc: 'https://png.pngtree.com/png-clipart/20240610/original/pngtree-checklist-3d-icon-transparent-png-image_15295989.png',
            actionText: 'Mehr erfahren',
            actionLink: '/',
            featured: true,
        },
        {
            type: "default",
            badge: "Ratgeber",
            title: 'Die richtige Schule für ihr Kind finden: Ein Leitfaden für Familien',
            author: 'Gabriella Bocanete',
            date: '16. April 2024',
            description: 'Die richtige Schule für Ihr Kind zu finden, ist eine der wichtigsten Entscheidungen, die Sie als Eltern treffen werden. Ob Sie in eine neue Gegend ziehen oder zum ersten Mal Bildungsoptionen erkunden, die Vielzahl von Schulen kann überwältigend sein. Hier ist ein knapper Leitfaden, um Ihrer Familie zu helfen, den Prozess zu navigieren und die beste Bildungsumgebung für Ihr Kind zu finden.',
            imageSrc: 'https://images.pexels.com/photos/3747505/pexels-photo-3747505.jpeg',
            actionText: 'Mehr erfahren',
            actionLink: '/',
            featured: false,
        },
    ]
};

export default settings;
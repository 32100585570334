import React, { useState } from "react";
import { Link } from "react-router-dom";
import Asset from "../helpers/Asset";
import settings from "../config/settings";

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => setIsOpen(!isOpen);
    const middleIndex = Math.ceil(settings.menu.length / 2);

    return (
        <header className={`w-full fixed top-0 left-0 z-[9999] h-[60px] md:h-[80px] flex items-center group bg-background ${isOpen ? 'is-active' : ''}`}>
            <div className="container max-w-[1440px] px-[20px] lg:px-[50px]">
                <ul className="flex items-center gap-[50px] justify-center">
                    {settings.menu.map((item, index) => (
                        <React.Fragment key={index}>
                            <MenuItem item={item} />
                            {index === middleIndex - 1 && <LogoItem />}
                        </React.Fragment>
                    ))}
                    <MobileMenuToggle handleClick={handleClick} />
                </ul>
            </div>
            <MobileNav isOpen={isOpen} handleClick={handleClick} menu={settings.menu} />
        </header>
    );
};

const MenuItem = ({ item }) => (
    <li className="hidden lg:inline-flex">
        <Link className="relative font-decorative text-[22px] after:absolute after:h-[2px] 
                after:bottom-[-3px] after:left-0 after:translate-x-[-8px] after:w-0 after:bg-text-color 
                after:rounded-md after:transition-width after:ease-in-out 
                hover:after:w-full hover:after:bg-primary active:after:w-full active:after:bg-link-active"
            to={item.to}>
            {item.label}
        </Link>
    </li>
);

const LogoItem = () => (
    <li className="mr-auto lg:mx-[100px] flex-nowrap" key="logo">
        <Link to="/">
            <img className="w-[100px] md:w-[150px] h-[40px] max-w-none" src={Asset(settings.logo.src)} alt={settings.logo.alt} />
        </Link>
    </li>
);

const MobileMenuToggle = ({ handleClick }) => (
    <li className="flex lg:hidden w-[32px] h-[20px] items-center justify-center cursor-pointer transition-opacity ease-linear group/menu" onClick={handleClick} aria-label="Toggle menu">
        <span className="w-[32px] h-[20px] inline-block relative">
            <span className="transition ease block absolute rounded-[4px] text-[0px] mt-[-2px] w-[32px] h-[4px] top-[2px] bg-text-color group-hover/menu:bg-primary group-active/menu:bg-button-active
                before:absolute before:block before:rounded-[4px] before:w-[32px] before:h-[4px] before:transition before:ease before:top-[8px] before:bg-text-color group-hover/menu:before:bg-primary group-active/menu:before:bg-button-active
                after:absolute after:block after:rounded-[4px] after:w-[32px] after:h-[4px] after:transition after:ease after:top-[16px] after:bg-text-color group-hover/menu:after:bg-primary group-active/menu:after:bg-button-active
                group-[.is-active]:transform group-[.is-active]:translate-y-2 group-[.is-active]:rotate-45 group-[.is-active]:bg-primary
                group-[.is-active]:before:transform group-[.is-active]:before:rotate-[45deg] group-[.is-active]:before:translate-x-[-4.5px] group-[.is-active]:before:translate-y-[-4px] group-[.is-active]:before:opacity-0 group-[.is-active]:before:bg-primary
                group-[.is-active]:after:transform group-[.is-active]:after:translate-x-[0px] group-[.is-active]:after:translate-y-[-16px] group-[.is-active]:after:rotate-[-90deg] group-[.is-active]:after:bg-primary">
                Menü
            </span>
        </span>
    </li>
);

const MobileNav = ({ isOpen, handleClick, menu }) => (
    <nav className={`fixed right-0 top-[60px] md:top-[80px] h-full w-full inline-flex ${isOpen ? 'pointer-events-auto' : 'pointer-events-none'} lg:hidden`}>
        <div onClick={handleClick} className={`w-full h-full absolute bg-white bg-opacity-70 z-10 backdrop-blur-sm transition ease-in-out ${isOpen ? 'opacity-100' : 'opacity-0'} cursor-pointer`} />
        <ul className={`flex flex-col justify-start gap-[20px] py-[50px] pr-[20px] pl-[28px] max-w-full md:max-w-[450px] w-full bg-white z-20 ml-auto transition ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-[100%]'}`}>
            {menu.map((item, index) => (
                <li className="inline-flex" key={index}>
                    <Link onClick={handleClick} className="relative font-decorative text-[22px] after:absolute after:h-[2px] 
                            after:bottom-[-3px] after:left-0 after:translate-x-[-8px] after:w-0 after:bg-text-color 
                            after:rounded-md after:transition-width after:ease-in-out
                            hover:after:w-full hover:after:bg-primary active:after:w-full active:after:bg-link-active"
                        to={item.to}>
                        {item.label}
                    </Link>
                </li>
            ))}
        </ul>
    </nav>
);

export default Navigation;

import React from 'react';
import { Link } from 'react-router-dom';

const Button = React.memo(({ to, href, elementType = 'link', className = '', children, ...props }) => {
    const commonProps = {
        className: className.replace(/\s+/g, ' ').trim(),
        ...props,
    };

    const renderButtonContent = () => <span>{children}</span>;

    if (elementType === 'button') {
        return (
            <button {...commonProps} type={props.type || 'button'}>
                {renderButtonContent()}
            </button>
        );
    }

    if (to !== undefined) {
        return (
            <Link to={to} {...commonProps}>
                {renderButtonContent()}
            </Link>
        );
    }

    return (
        <a href={href} {...commonProps}>
            {renderButtonContent()}
        </a>
    );
});

export default Button;
